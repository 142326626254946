import React from "react"
import {DrawerWrapper, FormWrapper, RadioButton, TitreForm} from "./UtilsForm"

const ProjetType = ({ state, dispatch }) => {



  return (
    <DrawerWrapper>
      <TitreForm>
        En quoi puis-je vous aider ?
      </TitreForm>
      <FormWrapper>
        <RadioButton state={state} dispatch={dispatch} id="design" value={"design"} label="Web design" name="projet" />
        <RadioButton state={state} dispatch={dispatch} id="mobile" value={"mobile"} label="Application mobile" name="projet" />
        <RadioButton state={state} dispatch={dispatch} id="dev" value={"dev&design"} label="Web design & dévelopement" name="projet" />
      </FormWrapper>
    </DrawerWrapper>
  )
}

export default ProjetType
