import React, { useReducer, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import Hello from "./Hello"
import Name from "./Name"
import ProjetType from "./ProjetType"
import ContactSlide from "./ContactSlide"
import BudgetSlide from "./BudgetSlide"
import OneLastThing from "./OneLastThing"
import ThankYou from "../ProjectStarter/ThankYou"
import { Error } from "./UtilsForm"

const totalSlides = 5

const initialState = {
  name: "",
  projet: "",
  budget: "",
  mail: "",
  phone: "",
  message: "",
  error: "",
  slide: 0,
}

function reducer(state, action) {
  switch (action.type) {
    case "changed_name": {
      return {
        ...state,
        name: action.nextName,
        error: "",
      }
    }
    case "changed_projet": {
      return {
        ...state,
        projet: action.nextProjet,
        error: "",
      }
    }
    case "changed_budget": {
      return {
        ...state,
        budget: action.nextBudget,
        error: "",
      }
    }
    case "changed_mail": {
      return {
        ...state,
        mail: action.nextMail,
        error: "",
      }
    }
    case "changed_phone": {
      return {
        ...state,
        phone: action.nextPhone,
        error: "",
      }
    }
    case "changed_consent": {
      return {
        ...state,
        consent: action.nextConsent,
        error: "",
      }
    }
    case "changed_message": {
      return {
        ...state,
        message: action.nextMessage,
        error: "",
      }
    }
    case "changed_error": {
      return {
        ...state,
        error: action.nextError,
      }
    }
    case "changed_slide": {
      return {
        ...state,
        slide: action.nextSlide,
        error: "",
      }
    }
    default: {
      throw Error("Unknown action: " + action.toString())
    }
  }
}

const FormProjet = () => {
  const [send, setSend] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)

  const handlePrev = () => {
    goPrev()
  }
  const goPrev = () => {
    dispatch({
      type: "changed_slide",
      nextSlide: state.slide - 1,
    })
  }
  const handleNext = () => {
    switch (state.slide) {
      case 0:
        goNext()
        break
      case 1:
        if (state.name === "") {
          dispatch({
            type: "changed_error",
            nextError:
              "Il m'est difficile de vous répondre si je ne sais pas à qui je m'adresse.",
          })
        }
        state.name !== "" && goNext()
        break
      case 2:
        break
      case 3:
        break
      case 4:
        if (state.phone !== "" || state.mail !== "") {
          return goNext()
        }
        dispatch({
          type: "changed_error",
          nextError:
            "Veuillez renseigner au moins un moyen de vous joindre sans quoi je ne pourrais pas vous répondre.",
        })
        break
      case 5:
        goNext()
      default:
        return null
    }
  }
  const goNext = () => {
    dispatch({
      type: "changed_slide",
      nextSlide: state.slide + 1,
    })
  }
  const sendMail = opts => {
    fetch("/.netlify/functions/sendmail", {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        console.log("email envoyé:", data)
        setSend(true)
      })
  }

  const handleSubmit = () => {
    if (state.slide === 5) {
      sendMail({
        senderName: state.name,
        senderEmail: state.email === "" ? "pas de mail" : state.email,
        message: `Demande de ${state.name}, pour un projet de ${state.projet}, avec un budget de ${state.budget}. A recontacter sur : ${state.phone} ${state.mail}. Message : "${state.message}"`,
      })
    }
  }

  const { slide } = state

  return (
    <>
      <motion.div className="absolute top-0 left-0 bg-blue-500 h-2" animate={{ width: `${(100 / totalSlides) * slide}%` }} />
      <AnimatePresence mode={"wait"}>
        HELLO
        <div>
          {slide === 0 && !send && <Hello />}
          {slide === 1 && !send && <Name state={state} dispatch={dispatch} />}
          {slide === 2 && !send && (
            <ProjetType key={"projettype"} state={state} dispatch={dispatch} />
          )}
          {slide === 3 && !send && (
            <BudgetSlide key={"budget"} state={state} dispatch={dispatch} />
          )}
          {slide === 4 && !send && (
            <ContactSlide key={"contact"} state={state} dispatch={dispatch} />
          )}
          {slide === 5 && !send && (
            <OneLastThing key={"onelastthing"} state={state} dispatch={dispatch} />
          )}
          {send && <ThankYou key={"merci"} />}
        </div>
        {state.error && <Error key={"error"} message={state.error} />}
      </AnimatePresence>
      {!send && (
        <div className="bottom-0 left-0 mt-12 flex w-full justify-between px-12 pb-12">
          <button
            className="btn-form cursor-pointer bg-green-300 text-eido-grey-700 disabled:cursor-default disabled:bg-gray-300"
            aria-label="Suivant"
            onClick={handlePrev}
            disabled={slide === 0 && true}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
          </button>
          {slide < 5 && (
            <button
              className="btn-form bg-green-300 text-eido-grey-700 transition-all hover:scale-125 active:scale-90"
              aria-label="Suivant"
              onClick={handleNext}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3"
                />
              </svg>
            </button>
          )}
          {slide === 5 && (
            <button
              className="btn-form w-14 bg-gray-200"
              onClick={handleSubmit}
              type={"submit"}
            >
              Envoyer
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default FormProjet
