import React from "react"
import { motion } from "framer-motion"
import {DrawerWrapper, PForm, TitreForm} from "./UtilsForm";

const Hello = () => {
  const helloAni = {
    initial: {},
    animate: {
      rotate: [60, -20, 30, -45, 5, 60, -20, 30, -45, 5],
      transition: {
        delay: .5,
        duration: 1,
      },
    },
  }

  return (
    <>
      <DrawerWrapper>
        <motion.div
          className="flex h-16 w-16 items-center justify-center rounded-full bg-eido-grey-100 text-xl"
          initial={"initial"}
          animate={"animate"}
          variants={helloAni}
        >
          👋
        </motion.div>
        <TitreForm>
          Hello there !
        </TitreForm>
        <PForm>
          Je suis ravi de vous voir ici, pour vous aider j'ai besoin de quelques
          informations.
        </PForm>
        <PForm>C'est parti !</PForm>
      </DrawerWrapper>
    </>
  )
}

export default Hello
