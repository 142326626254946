import React from 'react';
import {motion} from "framer-motion";

const ThankYou = () => {
    const containerAni = {
        initial: {
            scale: 0,
        },
        show: {
            scale: 1,
            transition: {
                staggerChildren: 1,
            },
        },
        exit: {},
    }
    const itemAni = {
        initial: {
            opacity: 0,
        },
        show: {
            opacity: 1,
        },
        exit: {},
    }

    return (
        <motion.div
            className="absolute mt-10 rounded-full bg-white p-2 text-eido-grey-700"
            variants={containerAni}
        >
            <motion.div variant={itemAni}>
                Merci ! Au plaisir de faire votre connaissance !
            </motion.div>
        </motion.div>
    )
}
export default ThankYou;