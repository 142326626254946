import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ButtonContact = ({ children, setIsOpen, setIsMessage, message}) => {
  return (
    <button onClick={() => {
      setIsOpen(true)
      setIsMessage(message)
    }} className="w-full rounded-full border border-white bg-transparent py-5 px-10 font-bold text-white hover:bg-white hover:text-eido-grey-900 md:text-2xl">
      {children}
    </button>
  )
}

const Footer = ({ setIsOpen, setIsMessage}) => {
  return (
    <footer className="relative overflow-hidden mt-20">
      <div className="absolute inset-0 bg-eido-grey-900">
        <StaticImage
          className="h-full w-full opacity-60"
          objectFit={"cover"}
          src="https://images.unsplash.com/photo-1621190462153-92bc87157fbe"
          alt={"footer"}
        />
        <div className="absolute bottom-2 right-2 p-2 text-xs text-white">
          Photo by{" "}
          <a href="https://unsplash.com/es/@marpfaa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Maria
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/sunrise-sky?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </div>
      </div>
      <div className="container relative mx-auto">
        <div className="py-8 md:py-32">
          <div className="text-center text-3xl font-bold uppercase text-white drop-shadow-2xl md:text-7xl">
            <span className="block">Et si on parlait</span>
            <span className="block">d'avenir?</span>
          </div>
        </div>
        <div className="flex flex-col gap-4 px-8 md:flex-row md:gap-16 md:px-2">
          <ButtonContact setIsOpen={setIsOpen} setIsMessage={setIsMessage} message={true}>M'envoyer un message</ButtonContact>
          <ButtonContact setIsOpen={setIsOpen} setIsMessage={setIsMessage}>Démarrer un nouveau projet</ButtonContact>
        </div>
      </div>
      <div className="container relative mx-auto px-4 pt-32 pb-8 text-center text-xs text-eido-grey-100">
        © eidolon design 2022 -{" "}
        <span>mentions légales</span>
      </div>
    </footer>
  )
}

export default Footer
