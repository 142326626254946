import React, { useEffect } from "react"
import { motion } from "framer-motion"
import Logo from "./Logo"

const Drawer = ({ isOpen, setIsOpen, children }) => {
  useEffect(() => {
    isOpen &&
      document.getElementsByTagName("html")[0].classList.add("overflow-hidden")
    return () => {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("overflow-hidden")
    }
  }, [isOpen])

  const containerAni = {
    hidden: {
      width: 0,
    },
    show: {
      width: "100%",
      transition: {
        delayChildren: .4
      }
    },
    exit: {
      width: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }

  const contentAni = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: "100%",
    },
    exit: {
      opacity: 0,
    },
  }

  return (
    <motion.div className="fixed top-0 left-0 z-40 h-full w-full">
      <div className="absolute inset-0 bg-black/60" />
      <div className="wrapper absolute top-0 left-0 h-full w-full pr-4 md:pr-12 md:max-w-screen-sm">
        <motion.div
          className="relative mr-12 h-full w-full bg-white py-6"
          initial={"hidden"}
          animate={"show"}
          exit={"exit"}
          variants={containerAni}
        >
          <motion.div className="content" variants={contentAni}>
            <div className="buttons flex items-end justify-between px-12">
              <div className="w-full max-w-[14rem]">
                <Logo color={"#B0B0B0"} />
              </div>
              <div>
                <button
                  className="flex h-8 w-8 items-center justify-center rounded-full bg-red-300"
                  onClick={() => setIsOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <main className=" px-12 py-12 flex flex-col">
              {children}
            </main>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default Drawer
