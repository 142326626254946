import React from "react"
import {DrawerWrapper, FormWrapper, InfoForm, InputText, TitreForm} from "./UtilsForm"

const ContactSlide = ({state, dispatch}) => {
  return (
    <DrawerWrapper>
      <TitreForm>Très bien ! Comment voulez-vous être recontacté ?</TitreForm>
      <InfoForm>
        Si vous avez un moyen de contact préféré renseignez uniquement celui-ci
      </InfoForm>
      <FormWrapper>
        <InputText
          label="Votre téléphone"
          name={"phone"}
          id={"phone"}
          type="text"
          placeholder="Votre téléphone"
          state={state} dispatch={dispatch}
        />
        <InputText
          label={"Votre email"}
          name={"mail"}
          id={"mail"}
          type="email"
          placeholder="john@doe.me"
          state={state} dispatch={dispatch}
        />
      </FormWrapper>
    </DrawerWrapper>
  )
}

export default ContactSlide
