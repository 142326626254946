import React, {useEffect, useReducer, useState} from "react"
import {Error, FormWrapper, InputText, TextArea} from "./FormProjet/UtilsForm"
import ThankYou from "./ProjectStarter/ThankYou";

const initialState = {
  name: "",
  mail: "",
  message: "",
  error: "",
}

function reducer(state, action) {
  switch (action.type) {
    case "changed_name":
      return {
        ...state,
        name: action.nextName,
        error: "",
      }
    case "changed_mail":
      return {
        ...state,
        mail: action.nextMail,
        error: "",
      }
    case "changed_message": {
      return {
        ...state,
        message: action.nextMessage,
        error: "",
      }
    }
    case "changed_error": {
      return {
        ...state,
        error: action.nextError,
      }
    }
    default: {
      throw Error("Unknown action: " + action.toString())
    }
  }
}

const FormMessage = () => {
  const [send, setSend] = useState(false)
  const [merci, setMerci] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)

  const sendMail = opts => {
    fetch("/.netlify/functions/sendmail", {
      method: "post",
      body: JSON.stringify(opts),
    })
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          console.log("email envoyé:", data)
          setMerci(true)
        })
        .catch(e => console.log(e))
  }

  const handleSubmit = () => {
    if (state.name !== "" && state.mail !== "") {
      sendMail({
        senderName: state.name,
        senderEmail: state.email === "" ? "pas de mail" : state.email,
        message: `Message de ${state.name}, a recontacter sur ${state.mail}. Message : "${state.message}"`,
      })
    }
  }

  useEffect(() => {
    if(state.name === "" || state.mail === "" || state.message === ""){
      return setSend(false)
    }
    return setSend(true)
  }, [state])

  return (
    <>
      {!merci && (
        <FormWrapper>
          <InputText
            name={"name"}
            required={true}
            label={"Votre nom"}
            state={state}
            dispatch={dispatch}
            type={"text"}
            id={"name"}
          />
          <InputText
            name={"mail"}
            type={"email"}
            label={"Votre Email"}
            state={state}
            dispatch={dispatch}
            id={"email"}
            required={true}
          />
          <TextArea
            state={state}
            id={"message"}
            label={"Votre Message"}
            dispatch={dispatch}
            name={"message"}
          />
          <button
            className="btn-form w-14 bg-gray-200 disabled:cursor-not-allowed"
            onClick={handleSubmit}
            type={"submit"}
            disabled={!send}
          >
            Envoyer
          </button>
        </FormWrapper>
      )}
      {merci && <ThankYou />}
    </>
  )
}

export default FormMessage
