import React from 'react';
import {DrawerWrapper, FormWrapper, PForm, RadioButton, TitreForm} from "./UtilsForm";

const BudgetSlide = ({state, dispatch}) => {
    return (
        <DrawerWrapper>
            <TitreForm>
                Quel budget avez vous en tête pour ce projet ?
            </TitreForm>
            <PForm>Ca n'a pas d'influence sur le devis mais sur le cadrage de la prestation.</PForm>
            <FormWrapper className="my-6 flex flex-col space-y-6">
                <RadioButton state={state} dispatch={dispatch} value={"less500"} key={"less500"} id={"less500"} name="budget" label="Moins de 500€"/>
                <RadioButton state={state} dispatch={dispatch} value={"500to1500"} key={"500to1500"} id={"500to1500"} name="budget" label="500 à 1500€"/>
                <RadioButton state={state} dispatch={dispatch} value={"1500to5000"} key={"1500to5000"} id={"1500to5000"} name="budget" label="1500€ à 5000€"/>
                <RadioButton state={state} dispatch={dispatch} value={"over5000"} key={"over5000"} id={"over5000"} name="budget" label="Plus 5000€"/>
            </FormWrapper>
        </DrawerWrapper>
    );
};

export default BudgetSlide;