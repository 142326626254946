import React from "react"
import {
  TitreForm,
  PForm,
  InfoForm,
  InputText,
  FormWrapper,
  DrawerWrapper,
} from "./UtilsForm"

const Name = ({ state, dispatch }) => {
  return (
    <DrawerWrapper>
      <TitreForm>Pouvez vous me donner votre nom ?</TitreForm>
      <PForm>
        C'est simplement pour m'adresser à vous de la manière dont vous le
        souhaitez.
      </PForm>
      <InfoForm>
        Vous pouvez inclure vos pronoms si vous en avez envie.
      </InfoForm>
      <FormWrapper>
        <InputText
          name={"name"}
          label={"Votre nom"}
          type={"text"}
          id={"name"}
          state={state}
          dispatch={dispatch}
          required={true}
        />
      </FormWrapper>
    </DrawerWrapper>
  )
}

export default Name
