import React from "react"
import { motion } from "framer-motion"

import logo from "../../images/logo-eido.svg"

// animation

const containerAni = {
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.6,
    },
  },
  exit: {
    staggerChildren: 0.1
  }
}

const logoAni = {
  hidden: {
    filter: "blur(15px)",
    opacity: 0,
    y: -20,
  },
  show: {
    filter: "blur(0px)",
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
  exit: {
    y: [0, -50, -100],
    opacity: [1, 0, 0],
    transition: {
      delay: 0.8,
    }
  }
}

const mottoAni = {
  hidden: {
    opacity: 0,
    y: 20,
    filter: "blur(15px)",
  },
  show: {
    opacity: 1,
    y: 0,
    filter: "blur(0px)",
    transition: {
      duration: 0.8,
    },
  },
  exit: {
    y: [0, 50, 100],
    opacity: [1, 0, 0],
    transition: {
      delay: 0.8,
    }
  }
}

const Loader = ({ setLoading }) => {
  return (
    <motion.div
      className="w-full h-screen flex items-center justify-center px-2"
      initial="hidden"
      animate="show"
      exit="exit"
      variants={containerAni}
      onAnimationComplete={() => setLoading(false)}
    >
      <motion.div className="max-w-2xl w-full">
        <motion.div className="bg-white relative z-10" variants={logoAni} layoutId="logo-eidolon">
          <img className={"w-full"} src={logo} alt={"eidolon logo"} />
        </motion.div>
        <motion.div className="flex flex-col md:flex-row items-center md:items-start justify-between uppercase font-bold">
          <motion.div variants={mottoAni}>développement</motion.div>
          <motion.div variants={mottoAni}>personnalisation</motion.div>
          <motion.div variants={mottoAni}>création</motion.div>
          <motion.div variants={mottoAni} className="absolute" />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default Loader
