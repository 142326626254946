import React from 'react';

const Logo = ({ color = "#636363", color2 = "#515151"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1394.53 123.22">
            <clipPath id="a" transform="translate(-170.46 -606.42)">
                <path d="M133.46 161.16h825.19v784.63H133.46z" transform="rotate(-60 546.057 553.481)"/>
            </clipPath>
            <clipPath id="b" transform="translate(-170.46 -606.42)">
                <path d="M1087.91 404.77h439.45v566.91h-439.45z" transform="rotate(-60 1307.638 688.224)"/>
            </clipPath>
            <g clipPath="url(#a)">
                <path d="M33.54 32.18V45.5h56.59v29.94H33.54v15.75h62.13v29.94H-.03V2.24h95.7v29.94zm79.81-29.94h33.58v118.89h-33.58zm54.87 0h44.32c41.54 0 71 24.57 71 59.36s-29.42 59.53-71 59.53h-44.3zm49.84 88.43c18 0 30.8-12.11 30.8-29.07s-12.8-28.9-30.8-28.9h-16.27v58zm77.19-29.09c0-33.4 29.59-61.44 64.72-61.44s64.73 28 64.73 61.44-29.59 61.61-64.73 61.61S295.25 95 295.25 61.58zm94.84 0c0-16.79-13.85-31-30.12-31s-30.11 14.19-30.11 31c0 17 13.85 31.15 30.11 31.15s30.12-14.15 30.12-31.15zm139.66 29.61v29.94h-89V2.24h33.57v89zm9.87-29.61c0-33.4 29.59-61.44 64.72-61.44s64.73 28 64.73 61.44-29.59 61.61-64.73 61.61S539.62 95 539.62 61.58zm94.84 0c0-16.79-13.85-31-30.12-31s-30.11 14.19-30.11 31c0 17 13.84 31.15 30.11 31.15s30.12-14.15 30.12-31.15zm50.71-59.34h33.57l46.38 63.68V2.24h33.58v118.89h-33.58l-46.38-63.69v63.69h-33.57z"/>
            </g>
            <g clipPath="url(#b)">
                <path d="M786.54 2.25h40.33c37 0 63.17 24.58 63.17 59.36s-26.14 59.54-63.17 59.54h-40.33zm40.33 108.69c30.46 0 52.09-20.42 52.09-49.33 0-28.73-21.63-49.15-52.09-49.15h-29.25v98.48zm99.17-98.13v42.4h65.07v10.73h-65.07v44.64h71.65v10.56h-82.9V2.25h82.9v10.56zm89.13 91.38 6.92-8.66c10.38 10.39 23.88 17.31 40.32 17.31 18.87 0 29.77-10.38 29.77-22.5 0-13-10.9-18.86-33.4-24-25.1-5.89-38.94-13.68-38.94-32.37 0-19.38 16.61-33.92 39.29-33.92 16.61 0 30.28 6.75 41.53 17l-6.92 8.53c-9.87-9.7-22.33-15.41-35.13-15.41-16.1 0-27.52 10.21-27.52 22.85 0 11.94 11.25 17.13 33.23 22.5 24.4 5.71 39.11 14.36 39.11 33.74 0 19-15.06 33.75-41.36 33.75-18.87.04-34.96-7.05-46.9-18.82zM1126.45 2.25h11.09v118.9h-11.07zm93.09 57.46h47.6v45.52c-11.25 10.9-29.25 18-46 18-32.88 0-60.92-28.21-60.92-61.61s28-61.44 60.92-61.44c16.79 0 34.79 6.93 46 18l-7.62 7.79c-9.34-9.52-24.4-15.4-38.42-15.4-27 0-49.67 23.36-49.67 51 0 27.87 22.67 51.23 49.67 51.23a57.87 57.87 0 0 0 35-12.11V70.27h-36.56zm76.68-57.46h11.08l76.15 100.9V2.25h11.09v118.9h-11.07l-76.15-100.9v100.9h-11.08z"/>
            </g>
        </svg>
    );
};

export default Logo;