import * as React from "react"
import { Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import Logo from "./Logo"
import Drawer from "./Drawer"
import FormProjet from "./FormProjet"

const LoveRain = () => {
  const containerAni = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.35,
        ease: "easeIn",
        duration: 12,
        when: "afterChildren",
      },
      transitionEnd: {
        display: "none",
      },
    },
    exit: {},
  }

  const rainAni = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: [0, 1, 1, 1, 0],
      y: ["0%", "100%"],
      transition: {
        ease: "easeIn",
        duration: 6,
      },
    },
  }

  const arrayItems = Array(10)

  return (
    <motion.div
      className="fixed inset-0 z-50 flex justify-between px-10"
      initial="hidden"
      animate="show"
      variants={containerAni}
    >
      {Array.apply(null, { length: 20 }).map((x, i) => {
        return (
          <motion.div key={i} variants={rainAni}>
            ❤️
          </motion.div>
        )
      })}
    </motion.div>
  )
}

const Header = ({ isLove, word, setIsOpen }) => {

  return (
    <header className="container mx-auto flex items-start justify-between py-6 px-2">
      <Link to={"/"} className="w-1/2 md:w-full md:max-w-xl">
        <motion.div className="fill-eido-grey-500 transition-all dark:fill-eido-grey-200">
          <Logo />
        </motion.div>
        {isLove ? <LoveRain /> : null}
        {word && <span>{word}</span>}
      </Link>
      <div>
        <button
          className="flex items-center space-x-2 rounded bg-gradient-to-t from-[rgba(255,106,0,1)] to-[rgba(255,157,0,1)] px-1 py-0.5 text-white md:px-4 md:py-1"
          onClick={() => setIsOpen(true)}
        >
          <span className="hidden text-xs md:inline-block md:text-lg">
            Démarrez votre projet
          </span>
          <span className="inline-block text-xs md:hidden md:text-lg">
            Start projet
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            />
          </svg>
        </button>
      </div>
    </header>
  )
}

export default Header
