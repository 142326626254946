import React from "react"
import {DrawerWrapper, FormWrapper, TextArea, TitreForm} from "./UtilsForm"

const OneLastThing = ({state, dispatch}) => {


  return (
    <DrawerWrapper>
      <TitreForm>Une dernière chose ?</TitreForm>
      <FormWrapper>
          <TextArea id={"message"} state={state} dispatch={dispatch} name={"message"} label={"Votre message"} />
      </FormWrapper>
    </DrawerWrapper>
  )
}

export default OneLastThing
