/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useEffect, useRef, useState } from "react"
import {AnimatePresence, motion} from "framer-motion"

import Header from "./Header"
import Loader from "./Loader"
import Footer from "./Footer"
import classNames from "classnames"
import Drawer from "./Drawer";
import FormProjet from "./FormProjet";
import FormMessage from "./FormMessage";

const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [isLove, setIsLove] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isMessage, setIsMessage] = useState(false)
  const [word, setWord] = useState("")
  const [dark, setDark] = useState(false)

  let chain = ""

  const prevWordRef = useRef()

  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevWordRef.current = word
  }, [word])

  const handleKeyDown = event => {
    chain = chain + event.key
    console.log(chain)
    if (chain.includes("cyp")) {
      console.error("Cyp is already")
      setIsLove(true)
    } else if (chain.includes("dark")) {
      setDark(true)
      document.getElementsByTagName("html")[0].classList.add("dark")
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true)
    //return document.removeEventListener("keydown", handleKeyDown, true)
  }, [])

  // animations
  const containerAni = {
    show: {
      transition: {
        staggerChildren: 0.35,
      },
    },
  }

  const wrapperAni = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
      staggerChildren: 0.35,
    },
  }

  const classes = classNames("dark:bg-eido-grey-900", {
    dark: dark,
  })

  return (
    <>
      {loading ? (
        <motion.div key="loader">
          <Loader setLoading={setLoading} />
        </motion.div>
      ) : (
        <motion.div
          key="layout-wrapper"
          initial="hidden"
          animate="show"
          exit="exit"
          variants={wrapperAni}
          className={classes}
        >
          <Header isLove={isLove} word={word} isOpen={isOpen} setIsOpen={setIsOpen} />
          <main>{children}</main>
          <Footer setIsOpen={setIsOpen} setIsMessage={setIsMessage} />
          <AnimatePresence>
            {isOpen && (
                <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
                  {!isMessage &&<FormProjet /> }
                  {isMessage && <FormMessage />}
                </Drawer>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </>
  )
}

export default Layout
