import React, { useEffect } from "react"
import { motion } from "framer-motion"
import classNames from "classnames";

const containerAni = {
  initial: {},
  show: {
    transition: {
      staggerChildren: 0.1,
      ease: [0.16, 1, 0.3, 1],
    },
  },
}

const titleAni = {
  initial: {
    x: -15,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
  },
}

const itemAni = {
  initial: {
    x: -15,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
  },
}

export const Error = ({ message }) => {
  return (
    <div className="flex items-center gap-2 rounded-lg border border-blue-500 bg-blue-200 p-2 text-blue-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
      <span className="flex-1">{message}</span>
    </div>
  )
}

export const DrawerWrapper = ({ children }) => {
  return (
    <motion.div initial={"initial"} animate={"show"} variants={containerAni}>
      {children}
    </motion.div>
  )
}

export const TitreForm = ({ children }) => {
  return (
    <motion.div
      className="mb-6 text-2xl font-bold text-eido-grey-700"
      variants={titleAni}
    >
      {children}
    </motion.div>
  )
}

export const PForm = ({ children }) => {
  return (
    <motion.p className="mt-10 text-eido-grey-500" variants={itemAni}>
      {children}
    </motion.p>
  )
}

export const InfoForm = ({ children }) => {
  return (
    <motion.span className="text-xs" variants={itemAni}>
      {children}
    </motion.span>
  )
}

export const InputWrapper = ({ children }) => {
  return <div>{children}</div>
}

export const TextArea = ({ label, value, name, id, state, dispatch }) => {

  const handleInputChange = e => {
    const typeName = `changed_${name}`
    const nameValue = `next${capitalizeFirstLetter(name)}`

    dispatch({
      type: typeName,
      [nameValue]: e.target.value,
    })
  }

  return (
    <motion.div variants={itemAni}>
      <textarea
        placeholder="Votre message"
        className={"w-full rounded-3xl border-2 border-b-green-300 p-4"}
        rows="8"
        value={state[name]}
        onChange={handleInputChange}
      ></textarea>
    </motion.div>
  )
}

export const RadioButton = ({ label, value, name, id, state, dispatch }) => {
  const goNext = () => {
    setTimeout(
      () =>
        dispatch({
          type: "changed_slide",
          nextSlide: state.slide + 1,
        }),
      500
    )
  }

  const handleInputChange = e => {
    const typeName = `changed_${name}`
    const nameValue = `next${capitalizeFirstLetter(name)}`

    dispatch({
      type: typeName,
      [nameValue]: e.target.value,
    })

    goNext()
  }

  return (
    <motion.div className={"flex w-full"} variants={itemAni}>
      <input
        id={id}
        className="hidden opacity-0"
        name={name}
        type="radio"
        value={value}
        checked={state[name] === value}
        onChange={handleInputChange}
      />
      <label
        htmlFor={id}
        className="label-radio w-full cursor-pointer rounded-full border-2 border-eido-grey-100 px-8 py-2 text-center transition-all"
      >
        {label}
      </label>
    </motion.div>
  )
}

export const InputText = ({
  label,
  type,
  id,
  name,
  state,
  dispatch,
  required = false,
}) => {
  const handleInputChange = e => {
    const typeName = `changed_${name}`
    const nameValue = `next${capitalizeFirstLetter(name)}`

    dispatch({
      type: typeName,
      [nameValue]: e.target.value,
    })
  }

  const handleKeyDown = e => {
    if (e.key !== "Enter") {
      return null
    }
    if (required && state[name] === "") {
      console.log("state", state)
      switch (name) {
        case "name":
          return dispatch({
            type: "changed_error",
            nextError:
              "Il m'est difficile de vous répondre si je ne sais pas à qui je m'adresse.",
          })
        case "phone":
          if(state["mail"] !== "") {
            return null
          }
          return dispatch({
            type: "changed_error",
            nextError: "J'ai besoin d'au moins un moyen de contact pour vous répondre"
          })
        case "mail":
          if(state.phone !== ""){
            return null
          }
          return dispatch({
            type: "changed_error",
            nextError: "J'ai besoin d'au moins un moyen de contact pour vous répondre"
          })
        default:
          return dispatch({
            type: "changed_error",
            nextError:
                "Ce champ ne doit pas être vide.",
          })
      }
    }
    dispatch({
      type: "changed_slide",
      nextSlide: state.slide + 1,
    })
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true)
  }, [state])

  const classes = classNames("w-full border-2 border-eido-grey-500 border-x-transparent border-t-transparent py-2 pl-4 pr-2 focus:bg-blue-300 focus:outline-none", {
    "focus:bg-red-200": state.error !== "",
  })

  return (
    <motion.div className="flex w-full" variants={itemAni}>
      <label className="absolute hidden">{label}</label>
      <input
        placeholder={label}
        value={state[name]}
        type={type}
        id={id}
        name={name}
        className={classes}
        onChange={handleInputChange}
        required={required}
      />
    </motion.div>
  )
}

export const FormWrapper = ({ children }) => {
  return <div className="my-6 flex flex-col space-y-6 pr-[20%]">{children}</div>
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const validateName = input_str => {
  return null
}

export const validatePhoneNumber = input_str => {
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

  return re.test(input_str)
}

export const validateEmail = input_str => {
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return re.test(input_str)
}
